// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-wkocjan-gatsby-theme-intro-src-pages-404-js": () => import("./../node_modules/@wkocjan/gatsby-theme-intro/src/pages/404.js" /* webpackChunkName: "component---node-modules-wkocjan-gatsby-theme-intro-src-pages-404-js" */),
  "component---node-modules-wkocjan-gatsby-theme-intro-src-templates-index-js": () => import("./../node_modules/@wkocjan/gatsby-theme-intro/src/templates/index.js" /* webpackChunkName: "component---node-modules-wkocjan-gatsby-theme-intro-src-templates-index-js" */),
  "component---src-pages-content-resources-index-js": () => import("./../src/pages/content/resources/index.js" /* webpackChunkName: "component---src-pages-content-resources-index-js" */),
  "component---src-pages-content-resources-raspberry-pi-battery-backup-js": () => import("./../src/pages/content/resources/raspberry-pi-battery-backup.js" /* webpackChunkName: "component---src-pages-content-resources-raspberry-pi-battery-backup-js" */),
  "component---src-pages-e-10475-anglia-refinishing-services-js": () => import("./../src/pages/e/10475/anglia-refinishing-services.js" /* webpackChunkName: "component---src-pages-e-10475-anglia-refinishing-services-js" */),
  "component---src-pages-e-17927-gng-computers-ltd-js": () => import("./../src/pages/e/17927/gng-computers-ltd.js" /* webpackChunkName: "component---src-pages-e-17927-gng-computers-ltd-js" */),
  "component---src-pages-e-18298-steve-lock-ltd-js": () => import("./../src/pages/e/18298/steve-lock-ltd.js" /* webpackChunkName: "component---src-pages-e-18298-steve-lock-ltd-js" */),
  "component---src-pages-e-36441-ignite-heating-spares-ltd-js": () => import("./../src/pages/e/36441/ignite-heating-spares-ltd.js" /* webpackChunkName: "component---src-pages-e-36441-ignite-heating-spares-ltd-js" */),
  "component---src-pages-e-36843-gamma-catering-equipment-js": () => import("./../src/pages/e/36843/gamma-catering-equipment.js" /* webpackChunkName: "component---src-pages-e-36843-gamma-catering-equipment-js" */),
  "component---src-pages-e-37017-laptop-repair-clinic-js": () => import("./../src/pages/e/37017/laptop-repair-clinic.js" /* webpackChunkName: "component---src-pages-e-37017-laptop-repair-clinic-js" */),
  "component---src-pages-felix-index-js": () => import("./../src/pages/felix/index.js" /* webpackChunkName: "component---src-pages-felix-index-js" */),
  "component---src-pages-peer-index-js": () => import("./../src/pages/peer/index.js" /* webpackChunkName: "component---src-pages-peer-index-js" */)
}

